import { constants } from 'buffer';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { AddEditCustomer } from '../../components/Customers/AddEditCustomer';
import { toaster, Message } from 'rsuite';
import { random } from 'lodash';
import {
  getCustomerFormAction,
  getCustomerFormModel,
  getCustomerFormValues,
  getContactFormModel,
  getcontactFormValues,
  getEditedCustomer,
} from '../../selectors/CustomerSelector';
import * as MasterDataSelector from '../../selectors/MasterDataSelector';
import {
  getMainLoggingParams,
  selectCompany,
} from '../../selectors/SystemSelector';
import * as Constants from '../../utils/Constants';
import {
  customerFormInitialValues,
  customerFormValidation,
  contactFormValidation,
} from '../../utils/Validations/CustomerValidations';

import api from "../../api/CustomerApi";

class AddEditCustomerContainer extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnChangeContact = this.handleOnChangeContact.bind(this);
    this.editContactContainer = this.editContactContainer.bind(this);
    this.deleteContactContainer = this.deleteContactContainer.bind(this);
    this.setValuesForm = this.setValuesForm.bind(this);
    this.contactFormModel = this.contactFormModel.bind(this);
    this.handleOnErrorValidation = this.handleOnErrorValidation.bind(this);
    this.handleOnErrorValidationContact =
      this.handleOnErrorValidationContact.bind(this);
    this.handleOnStatesSearch = this.handleOnStatesSearch.bind(this);
    this.handleOnChangeStates = this.handleOnChangeStates.bind(this);
    this.handleOnChangeStatesContact =
      this.handleOnChangeStatesContact.bind(this);
    this.handleOnChangeCities = this.handleOnChangeCities.bind(this);
    this.handleOnChangeCitiesContact =
      this.handleOnChangeCitiesContact.bind(this);
    this.handleOnChangeIdType = this.handleOnChangeIdType.bind(this);
    this.handleCheckAll = this.handleCheckAll.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.stateModal = this.stateModal.bind(this);
    this.defaultAdress = this.defaultAdress.bind(this);
    this.customerNew = this.customerNew.bind(this);
    this.setActive = this.setActive.bind(this);
  }

  stateModal(position, action) {
    this.setState({
      actionModal: action,
      positionEdit: position,
    });
  }

  defaultAdress(value) {
    this.setState({
      defaultAdress: value,
    });
  }

  customerNew(value) {
    this.setState({
      customerNew: value,
    });
  }

  setActive(value) {
    this.setState({
      active: value,
    });
  }

  async componentDidMount() {
    const customFields = await api.getCustomFields({entity: 'CUSTOMER'});
    const fields = customFields?.data?.items?.sort((a, b) => a.order - b.order);
    this.props.getChannels()
    this.props.getPaymentMethods({ active: true })
    this.props.getListPrice({ active: true })
    this.props.getInvoicingSettings({ active: true })
    const costCenterData = await api.getCostCenter()
    const dataLocations = await api.getLocations()
    const dataTaxes = await api.getTaxes({ type: 'WITHHOLDING' })

    this.setState({
      costCenterData: costCenterData?.data?.items,
      locationsData: dataLocations?.data?.items,
      taxesData: dataTaxes?.data?.items,
      fields: fields,
    });
    
    this.props.setCitiesDisabled(true);

    if (this.props.formAction === 'CREATE') {
      this.setState({
        active: true,
      });
      this.props.updateCustomerFormModel(
        customerFormValidation('CC', this.props.company),
      );
      this.props.updateContactFormModel(contactFormValidation());
      this.loadMasterData(true);
    } else {
      this.setState({
        active: this.props.editedCustomer?.active,
      });
      this.loadMasterData(false);
      this.props.updateCustomerFormModel(
        customerFormValidation(
          this.props.editedCustomer.idType,
          this.props.company,
        ),
      );
      this.props.updateContactFormModel(contactFormValidation());
      this.props.updateCustomerFormValues(this.props.editedCustomer);
      this.setStatesAndCities(this.props.editedCustomer.stateId);
    }
  }

  loadMasterData(reset) {
    reset && this.resetData();
  }

  contactFormModel() {
    this.props.updateContactFormModel(contactFormValidation());
  }

  setStatesAndCities(stateId) {
    this.props.searchStates({ orderBy: 'name:ASC' });
    this.props.searchCities({ stateId });
    this.props.searchCitiesContact({ stateId });
    this.props.setCitiesDisabled(false);
  }

  resetData() {
    const initialValues = customerFormInitialValues(
      this.props.paymentMethods[0]?.value,
      this.props.listPrice[0]?.value,
      this.props.channels[0]?.value,
      this.props.invoicingSettings[0]?.value,
      this.props.states[16]?.value,
    );
    this.props.updateCustomerFormValues(initialValues);
    this.setStatesAndCities(initialValues.stateId);
  }
  randomNumber() {
    const hexadecimal = new Array(
      '0', '1', '2','3','4','5','6',
      '7', '8', '9', 'A', 'B', 'C',
      'D', 'E', 'F',
    );
    let randomColor = '#';
    for (let i = 0; i < 6; i++) {
      const posarray = random(0, hexadecimal.length);
      randomColor += hexadecimal[posarray];
    }
    return randomColor;
  }
  
  async handleSubmit(form, saveAndCreateNew, byContact, customFields, createCustomerOrder) {
    if (!byContact) {
      const requiredFields = this.state?.fields?.filter(field => field?.mandatory);
      const incompleteFields = requiredFields.filter(field => !customFields?.[field?.title]);

      if (incompleteFields.length > 0) {
        const missingFieldsMessage = `Completa los siguientes campos dinámicos requeridos antes de guardar: ${incompleteFields.map(field => field?.title).join(', ')}`;
        toaster.push(
          <Message
            type="error"
            showIcon
            closable
            duration={20000}
          >
            {missingFieldsMessage}
          </Message>,
        );
        return;
      }
    }
    if (!form.check()) {
      return;
    } else {
      if (this.props.formValues?.customFields && !byContact) {
        this.props.formValues.customFields = Object.keys(this.props.formValues?.customFields || {})
        .filter(key => customFields[key] !== "")
        .map(key => ({
          title: key,
          value: customFields[key]
        }));
      } else {
        this.props.formValues.customFields = []
      }
      if (this.state?.active === undefined && this.props.formAction !== 'EDIT') {
        this.props.formValues.active = true
      } else if (this.state?.active === false) {
        this.props.formValues.active = false
      } else if (this.state?.active === true) {
        this.props.formValues.active = true
      }

      const dataFulfillmentType = this.props.fulfillmentTypes?.find(
        item => item.id === this.props.formValues.fulfillmentTypeId,
      );
      const dataCostCenter = this.state?.costCenterData?.find(
        item => item.id === this.props.formValues.costCenter,
      );
      const dataTaxes = this.state?.taxesData?.find(
        item => item.id === this.props.formValues.withholdings,
      );
        
      const taxesData = dataTaxes
      ? {
          description: dataTaxes.description,
          idERP: dataTaxes.idERP,
          percentage: dataTaxes.percentage,
          active: dataTaxes.active,
        }
      : undefined;
      const costCenterData = dataCostCenter
      ? {
          name: dataCostCenter.name,
          idERP: dataCostCenter.idERP,
          sellerIdERP: dataCostCenter.sellerIdERP,
          id: dataCostCenter.id
        }
      : undefined;

      const fulfillmentTypeData = dataFulfillmentType
      ? {
        id: dataFulfillmentType?.id,
        description: dataFulfillmentType?.description,
        deliveryType: dataFulfillmentType?.deliveryType,
        deliveryPartner: dataFulfillmentType?.deliveryPartner
      } : undefined

      if (this.props.formAction === 'CREATE' && !byContact) {
        let customer = this.props.formValues;
        const contacts = {
          address: this.props.formValues.address,
          city: this.props.formValues.city,
          lastName: this.props.formValues.lastName,
          mobile: this.props.formValues.mobile,
          name: this.props.formValues.name,
          primaryEmail: this.props.formValues.primaryEmail,
          state: this.props.formValues.state,
          defaultShippingAddress: false,
          color: this.randomNumber(),
        };
        if (customer?.contacts?.length > 0) {
          let defaultShippingAddress = false;
          customer?.contacts?.map((contact, index) => {
            if (contact?.defaultShippingAddress) {
              defaultShippingAddress = true;
            }
          });

          if (!defaultShippingAddress) {
            contacts.defaultShippingAddress = true;
          }

          customer?.contacts.push(contacts);
        } else {
          contacts.defaultShippingAddress = true;

          customer = { ...customer, contacts: [contacts] };

        }
        delete customer.fulfillmentTypeId

        const customerResponse = await new Promise((resolve, reject) => {
          this.props.createCustomer({
            customer: { ...customer, fulfillmentType: fulfillmentTypeData, costCenter: costCenterData, withholdings: taxesData },
            loggingParams: this.props.loggingParams,
            createNew: saveAndCreateNew,
            fullDataValidation: this.props.company.fullDataValidation,
            redirect: createCustomerOrder ? false : true,
            onSuccess: resolve
          });
        });
        if (createCustomerOrder && customerResponse) {
          this.customerNew(customerResponse);
        }
      } else if (this.props.formAction === 'EDIT' && !byContact) {
        const propToBeDeleted = 'fullName';
        delete this.props.formValues[propToBeDeleted];
        const customer = { ...this.props.formValues, fulfillmentType: fulfillmentTypeData, costCenter: costCenterData, withholdings: taxesData }
        delete customer.fulfillmentTypeId
        this.props.editCustomer({
          customer: customer,
          loggingParams: this.props.loggingParams,
          fullDataValidation: this.props.company.fullDataValidation,
        });
      } else if (byContact) {
        if (this.state.actionModal === 'edit') {
          this.props.updateContactFormValues();
          const contacts = [];
          this.props.formValues?.contacts?.forEach((contact, index) => {
            if (this.state.defaultAdress) {
              this.props.formValuesContact.defaultShippingAddress = true;
              if (contact?.defaultShippingAddress) {
                contact.defaultShippingAddress = false;
              }
              this.defaultAdress(false);
            }
            if (index !== this.state.positionEdit) {
              contacts.push(contact);
            } else {
              const objectContact = {
                address: this.props.formValuesContact?.address,
                city: this.props.formValuesContact?.city,
                color: this.props.formValuesContact?.color,
                defaultShippingAddress: this.props.formValuesContact?.defaultShippingAddress,
                lastName: this.props.formValuesContact?.lastName,
                mobile: this.props.formValuesContact?.mobile,
                name: this.props.formValuesContact?.name,
                primaryEmail: this.props.formValuesContact?.primaryEmail,
                state: this.props.formValuesContact?.state
              }
              contacts.push(objectContact);
            }
          });
          const customer = { ...this.props.formValues, contacts: contacts, fulfillmentType: fulfillmentTypeData, costCenter: costCenterData, withholdings: taxesData };
          const customerContact = { ...this.props.formValues, contacts: contacts, fulfillmentTypeId: fulfillmentTypeData?.id, costCenter: costCenterData?.id };
          delete customer.fulfillmentTypeId
          this.props.updateCustomerFormValues(customerContact);
          this.props.updateContactFormValues();
          // if (!(this.props.formAction === 'CREATE')) {
          //   this.props.editCustomer({
          //     customer: customer,
          //     contact: true,
          //   });
          // }
        } else {
          this.props.formValues?.contacts?.forEach((contact, index) => {
            this.props.formValuesContact.color = this.randomNumber();
            if (this.state.defaultAdress) {
              this.props.formValuesContact.defaultShippingAddress = true;
              if (contact?.defaultShippingAddress) {
                contact.defaultShippingAddress = false;
              }
              this.defaultAdress(false);
            } else {
              this.props.formValuesContact.defaultShippingAddress = false;
            }
          });
          const objectContact = {
            address: this.props.formValuesContact?.address,
            city: this.props.formValuesContact?.city,
            color: this.props.formValuesContact?.color,
            defaultShippingAddress: this.props.formValuesContact?.defaultShippingAddress,
            lastName: this.props.formValuesContact?.lastName,
            mobile: this.props.formValuesContact?.mobile,
            name: this.props.formValuesContact?.name,
            primaryEmail: this.props.formValuesContact?.primaryEmail,
            state: this.props.formValuesContact?.state
          }

          let customer = {
            ...this.props.formValues,
            fulfillmentType: fulfillmentTypeData,
            costCenter: costCenterData,
            withholdings: taxesData,
            contacts: this.props.formValues?.contacts
              ? [
                  objectContact,
                  ...this.props.formValues?.contacts,
                ]
              : [objectContact],
          };
          let customerContact = {
            ...this.props.formValues,
            fulfillmentTypeId: fulfillmentTypeData?.id,
            costCenter: costCenterData?.id,
            contacts: this.props.formValues?.contacts
              ? [
                  objectContact,
                  ...this.props.formValues?.contacts,
                ]
              : [objectContact],
          };

          if (!(this.props.formAction === 'CREATE')) {
            customer = {
              ...this.props.formValues,
              fulfillmentType: fulfillmentTypeData,
              costCenter: costCenterData,
              withholdings: taxesData,
              contacts: [
                objectContact,
                ...this.props.formValues?.contacts,
              ],
            };
            customerContact = {
              ...this.props.formValues,
              fulfillmentTypeId: fulfillmentTypeData?.id,
              costCenter: costCenterData?.id,
              contacts: [
                objectContact,
                ...this.props.formValues?.contacts,
              ],
            };
          }
          delete customer.fulfillmentTypeId
          this.props.updateCustomerFormValues(customerContact);
          this.props.updateContactFormValues();
          // if (!(this.props.formAction === 'CREATE')) {
          //   this.props.editCustomer({
          //     customer: customer,
          //     contact: true,
          //   });
          // }
        }
      }
    }
    if (saveAndCreateNew) {
      this.loadMasterData(true);
    }
  }

  handleCancel() {
    this.props.redirect('list-customers');
  }

  handleOnChange(formValues) {
    this.props.updateCustomerFormValues(formValues);
  }

  handleOnChangeContact(formValuesContact) {
    this.props.updateContactFormValues(formValuesContact);
  }

  editContactContainer(contact) {
    const contactEdit = {
      address: contact?.address,
      city: contact?.city,
      lastName: contact?.lastName,
      mobile: contact?.mobile,
      name: contact?.name,
      primaryEmail: contact?.primaryEmail,
      state: contact?.state,
      defaultShippingAddress: contact?.defaultShippingAddress,
      color: contact?.color,
      stateId: contact?.state?.id,
      cityId: contact?.city?.id,
    }
    this.props.searchCitiesContact({ stateId: contact?.state?.id });
    this.props.setCitiesDisabled(false);
    this.props.updateContactFormValues(contactEdit);
  }

  deleteContactContainer(position) {
    if (this.props.formValues?.contacts.length > 1) {
      const contacts = [];
      let contactToDelete = {};
      let changeDefaultShippingAddress = false;

      if (this.props.formValues?.contacts[position].defaultShippingAddress) {
        changeDefaultShippingAddress = true;
      }

      this.props.formValues?.contacts?.forEach((contact, index) => {
        if (index !== position) {
          if (changeDefaultShippingAddress) {
            contact.defaultShippingAddress = true;
            changeDefaultShippingAddress = false;
          }
          contacts.push(contact);
        } else {
          contactToDelete = contact;
        }
      });
      const customer = { ...this.props.formValues, contacts: contacts };

      this.props.updateCustomerFormValues(customer);
      this.props.updateContactFormValues();
      if (!(this.props.formAction === 'CREATE')) {
        this.props.editCustomer({
          customer: customer,
          contact: true,
        });

        toaster.push(
          <Message
            type="success"
            showIcon
            closable
            duration={Constants.NOTIFICATION_DURATION}>
            {`El contacto ${contactToDelete.name} ${contactToDelete.lastName} fue eliminado exitosamente`}
          </Message>,
        );
      }
    } else {
      toaster.push(
        <Message
          type="success"
          showIcon
          closable
          duration={Constants.NOTIFICATION_DURATION}>
          {`Siempre debe quedar un contacto por defecto.`}
        </Message>,
      );
    }
  }

  setValuesForm() {
    this.props.updateContactFormValues();
  }

  handleOnErrorValidation(formError) {
    this.props.updateCustomerFormErrors(formError);
  }

  handleOnErrorValidationContact(formErrorContact) {
    this.props.updateContactFormErrors(formErrorContact);
  }

  handleOnStatesSearch(input) {
    if (input.length > 3) {
      let keywords = {};
      keywords.filters = { name: input, orderBy: 'name:ASC' };
      this.props.searchStates(keywords);
    }
  }

  handleOnChangeStates(value) {
    if (value) {
      const selectedState = value;
      this.props.searchCities({ stateId: selectedState });
      this.props.setCitiesDisabled(false);

      // set state description
      const foundState = this.props.states.find(item => item.value === value);
      const newFormValues = this.props.formValues;
      newFormValues.state = {
        id: foundState.value,
        name: foundState.label,
        idSiigo: foundState.idSiigo,
      };
      newFormValues.stateId = foundState.value;
      this.props.updateCustomerFormValues(newFormValues);
    } else {
      this.props.setCitiesDisabled(true);
    }
  }
  handleOnChangeStatesContact(value) {
    if (value) {
      const selectedState = value;
      this.props.searchCitiesContact({ stateId: selectedState });
      this.props.setCitiesDisabled(false);

      // set state description
      const foundState = this.props.states.find(item => item.value === value);
      let newFormValues = this.props.formValuesContact;
      if (!newFormValues) {
        newFormValues = {}
      }
      newFormValues.state = {
        id: foundState.value,
        name: foundState.label,
        idSiigo: foundState.idSiigo,
      };
      newFormValues.stateId = foundState.value;
      this.props.updateContactFormValues(newFormValues);
    } else {
      this.props.setCitiesDisabled(true);
    }
  }

  handleOnChangeCities(value) {
    if (value) {
      const foundCity = this.props.cities.find(item => item.value === value);
      const newFormValues = this.props.formValues;
      newFormValues.city = {
        id: foundCity.value,
        name: foundCity.label,
        stateId: foundCity.stateId,
        idSiigo: foundCity.idSiigo,
      };
      newFormValues.cityId = foundCity.value;
      this.props.updateCustomerFormValues(newFormValues);
    }
  }

  handleOnChangeCitiesContact(value) {
    if (value) {
      const foundCity = this.props.citiesContact.find(item => item.value === value);
      let newFormValues = this.props.formValuesContact;
      if (!newFormValues) {
        newFormValues = {}
      }
      newFormValues.city = {
        id: foundCity.value,
        name: foundCity.label,
        stateId: foundCity.stateId,
        idSiigo: foundCity.idSiigo,
      };
      newFormValues.cityId = foundCity.value;
      this.props.updateContactFormValues(newFormValues);
    }
  }

  handleOnChangeIdType(value) {
    this.props.updateCustomerFormModel(
      customerFormValidation(value, this.props.company),
    );
  }

  handleCheckAll(value) {
    this.setActive(value)
  }
  render() {
    const {
      setCustomerNew,
      createCustomerOrder,
      ...props
    } = this.props;
    if (this.state?.customerNew) {
      setCustomerNew(this.state?.customerNew)
    }

    return (
      <div>
        <AddEditCustomer
          formValues={this.props.formValues}
          formValuesContact={this.props.formValuesContact}
          formAction={this.props.formAction}
          model={this.props.formModel}
          cities={this.props.cities}
          citiesContact={this.props.citiesContact}
          states={this.props.states}
          channels={this.props.channels}
          invoicingSettings={this.props.invoicingSettings}
          listPrice={this.props.listPrice}
          customerIdType={Constants.CUSTOMER_IDTYPE}
          paymentMethods={this.props.paymentMethods}
          paymentTerms={Constants.PAYMENT_TERMS}
          fiscalResponsibility={Constants.FISCAL_RESPONSIBILITY}
          taxRegime={Constants.TAX_REGIME}
          costCenter={this.state?.costCenterData}
          withholdings={this.state?.taxesData}
          fulfillmentTypeId={this.props.fulfillmentTypes}
          defaultLocationId={this.state?.locationsData}
          actionOnShipment={Constants.ACTIONS_ONSHIPMENT}
          citiesDisabled={this.props.citiesDisabled}
          onChange={this.handleOnChange}
          onErrorValidation={this.handleOnErrorValidation}
          onStatesSearch={this.handleOnStatesSearch}
          onStatesChange={this.handleOnChangeStates}
          onCitiesChange={this.handleOnChangeCities}
          onIdTypeChange={this.handleOnChangeIdType}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
          onCitiesChangeContact={this.handleOnChangeCitiesContact}
          handleCheckAll={this.handleCheckAll}
          onStatesChangeContact={this.handleOnChangeStatesContact}
          onErrorValidationContact={this.handleOnErrorValidationContact}
          onChangeContact={this.handleOnChangeContact}
          contactFormModel={this.contactFormModel}
          contactModel={this.props.formModelContact}
          editContactContainer={this.editContactContainer}
          setValuesForm={this.setValuesForm}
          deleteContactContainer={this.deleteContactContainer}
          stateModal={this.stateModal}
          defaultAdress={this.defaultAdress}
          active={this.state?.active}
          fields={this.state?.fields}
          createCustomerOrder={createCustomerOrder}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    states: MasterDataSelector.getFoundStates(state),
    cities: MasterDataSelector.getFoundCities(state),
    citiesContact: MasterDataSelector.getFoundCitiesContact(state),
    citiesDisabled: MasterDataSelector.getCitiesDisabled(state),
    channels: MasterDataSelector.getChannels(state),
    invoicingSettings: MasterDataSelector.getInvoicingSettings(state),
    paymentMethods: MasterDataSelector.getPaymentMethods(state),
    fulfillmentTypes: MasterDataSelector.getFulfillmentTypesList(state),
    listPrice: MasterDataSelector.getListPrice(state),
    formValues: getCustomerFormValues(state),
    formValuesContact: getcontactFormValues(state),
    formModel: getCustomerFormModel(state),
    formModelContact: getContactFormModel(state),
    formAction: getCustomerFormAction(state),
    loggingParams: getMainLoggingParams(state),
    editedCustomer: getEditedCustomer(state),
    company: selectCompany(state),
  };
};
export default connect(mapStateToProps, actions)(AddEditCustomerContainer);
