import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDownloadDataMutation, useLazyFindProductQuery, useSynchronizationsProductMutation } from "@api/query/productApi/productApi";
import { FindProductRequest, Product } from "@api/query/productApi/types";
import { DataGrid, DataGridRowContent, DataGridToolbar, GlobalFilterCallback, HighlightText, cellsById } from "@components/DataGrid";
import PageHeader from "@components/PageHeader";
import InfoIcon from '@rsuite/icons/InfoRound';
import { FormattedMessage, FormattedNumberParts, useIntl } from "react-intl";
import { Close as CloseIcon, Edit as EditIcon } from '@rsuite/icons';
import { useDispatch, useSelector } from "react-redux";
import { useLazyPagination } from "@containers/PriceList/UseLazyPagination";
import { getTaxes, redirectCreateProduct, setOpenAuthModal } from "@actions/index";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { CellProps, Column } from "react-table";
import dayjs, { dayjsCalendar, dayjsRanges, formats } from "@utils/dayjs";
import PlusIcon from '@rsuite/icons/Plus';
import { CheckPicker, Checkbox, DateRangePicker, Divider, IconButton, Message, Tooltip, Whisper, toaster } from "rsuite";
import { AddEditProductModal } from "./components/AddEditProductModal";
import { DEFAULT_PRODUCT_DATA } from "./constants/product";
import { FaFileExcel, FaSync } from 'react-icons/fa';
import { ACTIVE, NOTIFICATION_DURATION, NOTIFICATION_SUCESS_QUICK_DURATION } from "@utils/Constants";
import { getMainLoggingParams } from "@selectors/SystemSelector";
import { ModalExportData } from "@components/Modals/ModalExportData";

export default function ListProductsContainer() {
	const dispatch = useDispatch();
	const [productEdit, setProductEdit] = useState({})
	const [openEditModal, setOpenEditModal] = useState(false);
	const [openDetailModal, setOpenDetailModal] = useState(false);
	const [openCreateModal, setOpenCreateModal] = useState(false);
	const [extraQueryValues, setExtraQueryValues] = useState({});
	const [triggerGetProducts, result] = useLazyFindProductQuery()
	const [openExportData, setOpenExportData] = useState(false);
	const { maxNumberDownloadAllowed } = useSelector(getMainLoggingParams);

	const [setDownload] = useDownloadDataMutation();

	useEffect(() => {
		dispatch(getTaxes())
	}, [])

	const trigger = useCallback(
		(value: FindProductRequest) => {
			return triggerGetProducts({
				...value,
			})
				.then(res => {
					if ((res?.error as FetchBaseQueryError)?.status === 401) {
						dispatch(setOpenAuthModal({ open: true }))
					}
				})
				.catch(err => {
					dispatch(setOpenAuthModal({ open: true }))
				})
		},
		[triggerGetProducts],
	);

	const [getSynchronizationsProducts, synchronizationsProducts] = useSynchronizationsProductMutation();

	useEffect(() => {
		if (synchronizationsProducts?.data) {
			toaster.push(
        <Message
          type="info"
          showIcon
          closable
          duration={NOTIFICATION_SUCESS_QUICK_DURATION}
        >
          {synchronizationsProducts?.data?.message}
        </Message>,
      );
		}
		if (synchronizationsProducts?.error) {
			toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={NOTIFICATION_SUCESS_QUICK_DURATION}
        >
          {'Error al procesar la información'}
        </Message>,
      );
		}
	}, [synchronizationsProducts])

	const { virtualArray, setViewRange, setExtraQuery, resetPagination } =
		useLazyPagination({
			trigger,
			result,
			initialLimit: 100,
      initialExtraQuery: { orderBy: 'createdAt:DESC' }
		});

	const handleEditProduct = (product: Product) => {
		setProductEdit(product)
		setOpenEditModal(true)
	}

	const handleOnClickExportCSV = () => {
		if (virtualArray?.ref?.length > 0) {
			setOpenExportData(true)
		} else {
			toaster.push(
				<Message
					type="error"
					showIcon
					closable
					duration={NOTIFICATION_DURATION}
				>
					{'La consulta seleccionada no arroja datos.'}
				</Message>
			);
		}
  };
	const submitExport = (data) => {
		const body = { ...data, ...extraQueryValues }
		setDownload(body)
			.then(resp => {
			try {
				const menssageError = resp
				if (menssageError) {
					toaster.push(
						<Message
							type="success"
							showIcon
							closable
							duration={NOTIFICATION_DURATION}
						>
							{'El archivo se está generando. Recibirás en segundos un e-mail con un link para su descarga.'}
						</Message>
					);
				}
			} catch (error) {
				console.error('Error en la promesa:', error);
			}
		})
  };

	const columns = useMemo<Column<Product>[]>(
		(): Column<Product>[] => [

			{
				id: 'name',
				Header: <FormattedMessage id="product" />,
				accessor: row => row.name,
				width: 250,
				maxWidth: 250,
				minWidth: 250,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind
							? undefined
							: `name:${kind.toUpperCase()}`,
					}));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						orderBy: !kind
							? undefined
							: `name:${kind.toUpperCase()}`,
					}));
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;
					setExtraQuery(value => ({
						...value,
						name: filterValue.trim() ? filterValue : undefined,
					}));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						name: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
			{
				id: 'sku',
				Header: <FormattedMessage id="sku" />,
				accessor: row => row.sku,
				width: 200,
				maxWidth: 200,
				minWidth: 200,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind
							? undefined
							: `sku:${kind.toUpperCase()}`,
					}));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						orderBy: !kind
							? undefined
							: `sku:${kind.toUpperCase()}`,
					}));
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;
					setExtraQuery(value => ({
						...value,
						sku: filterValue.trim() ? filterValue : undefined,
					}));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						sku: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
			{
				Header: <FormattedMessage id="gross-price" />,
				accessor: 'priceBeforeTax',
				minWidth: 80,
				width: 80,
				maxWidth: 80,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind ? undefined : `${columnId}:${kind.toUpperCase()}`,
					}));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						orderBy: !kind ? undefined : `${columnId}:${kind.toUpperCase()}`,
					}));
				},
				Filter: () => null,
				Cell: ({ value }) => (
					<FormattedNumberParts
						value={value}
						maximumFractionDigits={2}
						minimumFractionDigits={2}>
						{parts => (
							<span className="font-light text-base">
								<span className="mr-1 select-none">$</span>
								{parts.slice(0, -1).reduce((str, part) => str + part.value, '')}
								<span className="text-xs">{parts.at(-1)!.value}</span>
							</span>
						)}
					</FormattedNumberParts>
				),
			},
			{
				id: 'tax',
				Header: <FormattedMessage id="taxes" />,
				accessor: row => row.tax,
				width: 80,
				maxWidth: 80,
				minWidth: 80,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind
							? undefined
							: `tax:${kind.toUpperCase()}`,
					}));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						orderBy: !kind
							? undefined
							: `tax:${kind.toUpperCase()}`,
					}));
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;
					setExtraQuery(value => ({
						...value,
						tax: filterValue.trim() ? filterValue : undefined,
					}));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						tax: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
				Cell: ({ value }) => (
					<div>
						<HighlightText text={value} />%
					</div>
				)
			},
      {
				Header: <FormattedMessage id="sale-price" />,
				accessor: 'priceAfterTax',
				minWidth: 80,
				width: 80,
				maxWidth: 80,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind ? undefined : `${columnId}:${kind.toUpperCase()}`,
					}));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						orderBy: !kind ? undefined : `${columnId}:${kind.toUpperCase()}`,
					}));
				},
				Filter: () => null,
				Cell: ({ value }) => (
					<FormattedNumberParts
						value={value}
						maximumFractionDigits={2}
						minimumFractionDigits={2}>
						{parts => (
							<span className="font-light text-base">
								<span className="mr-1 select-none">$</span>
								{parts.slice(0, -1).reduce((str, part) => str + part.value, '')}
								<span className="text-xs">{parts.at(-1)!.value}</span>
							</span>
						)}
					</FormattedNumberParts>
				),
			},
			{
				id: 'createdAt',
				Header: <FormattedMessage id="createdAt" />,
				accessor: data => new Date(data.createdAt),
				minWidth: 150,
				width: 200,
				maxWidth: 200,
				sortType: 'datetime',
				onToggleSortBy(kind, columnId) {
          setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `createdAt:${kind.toUpperCase()}`,
          }));
					setExtraQueryValues(prevValues => ({
						...prevValues,
						orderBy: !kind
							? undefined
							: `createdAt:${kind.toUpperCase()}`,
					}));
				},
				Cell: ({ value, inline, state }: CellProps<Product, Date>) => {
					if (value.toString() === "Invalid Date") {
						return null
					}
					const date = dayjs(value);
					if (inline) {
						return (
							<HighlightText
								text={dayjsCalendar(date)}
								subtext={state.globalFilter}
							/>
						);
					}

					return (
						<>
							<Whisper
								trigger={['click', 'hover', 'active']}
								placement="bottomStart"
								speaker={<Tooltip>{date.format(formats.datetime)}</Tooltip>}>
								<div className="text-sm font-light first-letter:capitalize">
									<HighlightText
										text={dayjsCalendar(date)}
										subtext={state.globalFilter}></HighlightText>
								</div>
							</Whisper>
						</>
					);
				},
				Filter({ column }) {
					const { id, filterValue, setFilter } = column;

					return (
						<DateRangePicker
							id={id}
							name={id}
							size="xs"
							block
							showOneCalendar
							value={filterValue || []}
							onChange={setFilter}
							format="dd/MM/yyyy"
							ranges={dayjsRanges([
								'today',
								'tomorrow',
								'yesterday',
								'last7Days',
								'next7Days',
							])}
						/>
					);
				},
				filter: (rows, _, filterValue) => {
					setExtraQuery(value => ({
						...value,
						fromCreatedAt: filterValue?.[0],
						toCreatedAt: filterValue?.[1],
					}));

					return rows;
				},
			},
			{
				id: 'active',
				Header: <FormattedMessage id="status" />,
				accessor: row => row.active,
				sticky: true,
				width: 70,
				maxWidth: 100,
				minWidth: 70,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind ? undefined : `${columnId}:${kind.toUpperCase()}`,
					}));
				},
				Filter({ column }) {
					const { id, filterValue, setFilter } = column;

					return (
						<CheckPicker
							id={id}
							name={id}
							placeholder="-"
							size="xs"
							data={ACTIVE}
							block
							searchable={false}
							cleanable={false}
							value={filterValue || []}
							onChange={setFilter}
						/>
					);
				},
				Cell: ({ value, state }) => (
					<div>{<Checkbox checked={value} />}</div>
				),
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;

					setExtraQuery(value => ({
						...value,
						active: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
			{
				id: 'actions',
				Header: <FormattedMessage id="action" />,
				// accessor: row => row.id,
				width: 100,
				maxWidth: 100,
				minWidth: 100,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind
					}));
				},
				Cell: ({ value, row }) => {
					const id = row.original.id
					return (
						<div>
							<IconButton
								className="mx-1 -mt-1"
								size="sm"
								icon={<EditIcon />}
								onClick={() => {
									if (!openEditModal) {
										handleEditProduct(row.original)
									}
								}}
							/>
						</div>
					)
				}
				,
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;
					setExtraQuery(value => ({
						...value,
						actions: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},

		], []
	)

	const handleSync = (products: Record<string, Product>) => {
    let flag = true;
    const selectedProducts = Object.values(products);
    if (selectedProducts?.length === 0) {
      flag = false;
      toaster.push(
        <Message
          type="warning"
          showIcon
          closable
          duration={NOTIFICATION_DURATION}>
          {'Debes seleccionar al menos un producto'}
        </Message>,
      );
    }
    if (selectedProducts?.length > 20 && selectedProducts?.length !== 0) {
      flag = false;
      toaster.push(
        <Message
          type="warning"
          showIcon
          closable
          duration={NOTIFICATION_DURATION}>
          {'Debes seleccionar un máximo de 20 productos'}
        </Message>,
      );
    }
		if (flag) {
			let parentequal = true
			if (selectedProducts?.length >= 2) {
				let referenciaParents: string[] | null = null;
				selectedProducts.forEach((product, index) => {
					const { externalIds } = product;

					if (index === 0) {
						referenciaParents = externalIds.map(externalId => externalId.parent);
					} else {
						const currentParents = externalIds.map(externalId => externalId.parent);

						if (!arraysEqual(currentParents, referenciaParents)) {
							parentequal = false;
						}
					}
				});

				function arraysEqual(arr1: string[], arr2: string[]) {
					if (arr1.length !== arr2.length) {
						return false;
					}
					const sortedArr1 = [...arr1].sort();
					const sortedArr2 = [...arr2].sort();
					for (let i = 0; i < sortedArr1.length; i++) {
						if (sortedArr1[i] !== sortedArr2[i]) {
							return false;
						}
					}
					return true;
				}
			} else {
				parentequal = false
			}
			const ids = selectedProducts.map(obj => obj.id);
			getSynchronizationsProducts({
				ids: ids,
				groupIntoSingleProduct: parentequal
			});
		}
  };

		const { $t } = useIntl();


	const rowContent = useCallback<DataGridRowContent<Product>>(
		(index, row, { viewport }) => {
			if (viewport.isWide) return;

			const cells = cellsById(row.cells);
			
			return (
				<div
					className={`flex flex-row justify-between items-stretch py-2 px-4 border-y -mb-px border-gray-200`}>
					<div className="flex flex-col flex-nowrap flex-1">
						<div className="mb-1 flex items-center text-xs text-gray-500">
							<span className="flex flex-row">
								{cells.sku.render('Cell')}
							</span>
							<span className="middle-dot w-1 h-1 mx-2 bg-current rounded" />
							<span className="flex flex-row">
								{cells.createdAt.render('Cell', { inline: true })}
							</span>

						</div>

						<span className="mb-0.5 text-lg font-normal text-gray-700">
							{cells.name.render('Cell')}
						</span>

						<div className="mb-0.5 text-gray-500">

							<span className="flex flex-row">
								{cells.priceBeforeTax.render('Cell')}
							</span>
							<span className="flex flex-row">
								{cells.tax.render('Cell')}
							</span>
						</div>
					</div>

					<div className="flex items-center">
						<div>
							<span>
								{cells.active.render('Cell')}
							</span>
						</div>
						<div>
							<span>{cells.actions.render('Cell')}</span>
						</div>

					</div>
				</div>
			);
		},
		[],
	);

	const topToolbar = useCallback<DataGridToolbar<Product>>(
		({ filteredFlatRows, selectedData, rows, columns }) => {
			const statusColumn = columns.find(({ id }) => id === 'status');

			return (
				<>
					<Divider vertical className="rs-divider-vertical" />
					<IconButton
            className="mx-1 bg-gray-100 hover:shadow-md"
            size="sm"
            icon={<FaFileExcel className="rs-icon text-green-500" />}
            title="Exportar a csv"
						onClick={() => handleOnClickExportCSV()}></IconButton>
					<Divider vertical className="rs-divider-vertical" />
					<IconButton
            className="ml-0 mx-1 bg-gray-100 hover:shadow-md"
            size="sm"
            title={'Sincronizar productos'}
            onClick={() => handleSync(selectedData)}
            icon={
              <FaSync className="rs-icon text-blue-400" />
            }></IconButton>
					<Divider vertical className="rs-divider-vertical" />
					<IconButton
						className="ml-0 mx-1 bg-gray-100 hover:shadow-md"
						size="sm"
						icon={<PlusIcon />}
						title="Crear Producto"
						onClick={() => setOpenCreateModal(true)}

					/>
					<Divider vertical className="rs-divider-vertical" />
				</>
			);
		},
		[],
	);

	const globalFilter = useCallback<GlobalFilterCallback<Product>>(
		(rows, columnIds, filterValue: string) => {
			setExtraQuery(value => ({
				...value,
				keyword: filterValue.trim() ? filterValue : undefined,
			}));
			return rows;
		},
		[],
	);





	return (<div className="h-screen flex flex-col pb-1">
		<PageHeader title={<FormattedMessage id="products" />} />
		{openExportData && (
			<ModalExportData
				title={<FormattedMessage id="export-data-products" />}
				visible={openExportData}
				onClose={() => {
					setOpenExportData(false)
				}}
				submitExport={submitExport}
				disabled={virtualArray?.ref?.length > maxNumberDownloadAllowed}
				pages={virtualArray?.ref?.length / maxNumberDownloadAllowed}
			/>
		)}
		<DataGrid
			className="flex-1"
			data={virtualArray.ref}
			totalCount={virtualArray.ref.length}
			rangeChanged={setViewRange}
			columns={columns}
			selectable
			rowContent={rowContent}
			topToolbar={topToolbar}
			globalFilter={globalFilter}
			overscan={0}
			increaseViewportBy={500}
			autoResetFilters={false}
			autoResetSelectedRows={false}
			autoResetSortBy={false}
			loading={result.isFetching}
		/>


		<AddEditProductModal
			productInfo={DEFAULT_PRODUCT_DATA}
			isEditing={false}
			title="Crear Producto"
			visible={openCreateModal} onClose={() => {
				setOpenCreateModal(false)
			}}
		/>

		<AddEditProductModal
			productInfo={productEdit}
			isEditing={true}
			title="Editar Producto"
			visible={openEditModal}
			onClose={() => {
				setOpenEditModal(false)
			}}
		/>
	</div>)

}