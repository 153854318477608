import { CustomFieldsDataType } from '@api/query/customFieldsApi/types';
import { ORDOX_VERSION } from '../utils/Constants';

export const initialState = Object.freeze({
  formActions: {
    CREATE_ORDER: 1,
    EDIT_ORDER: 2,
  },
  overallParams: {
    company: '',
    version: ORDOX_VERSION,
    companyId: undefined as unknown as string,
  },
  company: {
    id: '',
    erp: '',
    taxAccountable: false,
    ecomSite: '',
    rounding: 0,
    deliveryHoursAhead: 2,
    maxNumberDownloadAllowed: 0,
  },
  orderCaptureAction: 'CREATE',
  customerCaptureAction: 'CREATE',
  customer: [],
  loadingVisible: false,
  items: [],
  listPrice: [],
  status: [],
  selectedOrderTracking: [],
  paymentMethods: [],
  fullDeliverySlots: [],
  selectedDateDeliverySlots: [],
  updateOrderStatusStatus: '',
  orderTrackingVisible: false,
  trackingComments: '',
  updateOrderStatusVisible: false,
  newOrder: {
    items: [],
    customer: {
      value: null,
      label: '',
      phone: '',
      address: '',
      email: '',
      paymentMethodId: undefined,
    },
    deliverySlot: {
      value: undefined,
      start: undefined,
      end: undefined,
      label: undefined,
      ordersCapacity: undefined,
    },
    paymentMethod: {},
    paymentMethodId: '',
    promisedDeliveryDate: '',
    promisedDeliveryDateView: '',
    comments: '',
    notes: '',
    channel: {},
    invoicingSettings: {},
    actionOnShipment: '',
    currentStatusId: 0,
    deletedItems: [],
  } as unknown as NewOrderState,
  orderTracking: {},
  orders: {
    list: [],
    filters: {},
    paging: {
      pageSize: 20,
      currentPage: 1,
      totalRows: 20,
    },
    checkedKeys: [],
    exportDisabled: true,
    isFirstLoad: true,
    orderInvoiceURL: 0,
    purchaseOrderURL: 0,
  },
  ordersHistory: {
    list: [],
    filters: [],
    paging: {
      pageSize: 50,
      currentPage: 1,
      totalRows: 20,
    },
  },
  ordersFilters: {
    Id: {
      column: {},
      filterTerm: null,
    },
  },
  productionListGridFilter: {},
  activeUser: {
    id: '',
    name: '',
    email: '',
    roleId: 0,
    authorized: false,
    ready: false,
    fulfillmentLocationId: '',
    permissions: {},
    company: {},
  },
  redirectTo: {
    editForm: false,
    home: false,
    listOrder: true,
    payload: null,
  },
  showSpinner: false,
  showNotification: false,
  mobileClient: false,
  buttonDisabled: false,
  lastUpdateOrdersGrid: '',
  invoiceConfirmActionVisible: false,
  confirmOrderActionVisible: false,
  slotSatus: {},
  productionList: [],
  productionListFilter: {},
  mainOpsDashboard: {
    currentMonthOnTime: 0,
    currentMonthOnTimeOrders: 0,
    currentMonthOffTimeOrders: 0,
    totalUnits: [],
    totalOrders: [],
    totalAmount: [],
    dailyOrdersSeries: [],
    dailyOrdersData: [],
    topCustomersData: [],
    topCustomersSeries: [],
  },
  mainOpsDashboardController: {
    buttonGroupStatus: [
      { button: 'lastMonth', active: false },
      { button: 'currentMonth', active: true },
      { button: 'liveStatus', active: false },
    ],
    lastUpdate: null,
    currentSelection: 'CURRENT_MONTH',
    topTenDimension: 'cop',
  },
  mainOpsDashboardLiveStatus: {
    ordersSet: [],
    amountsSet: [],
    unitsSet: [],
  },
  masterData: {
    selectedState: 2,
    statesList: [],
    foundStates: [],
    fulfillmentTypes: [],
    foundCities: [],
    foundCitiesContact: [],
    citiesDisabled: true,
    selectedStateCities: [],
    channels: [],
    invoicingSettings: [],
    taxes: [],
    listPrice: [],
    contacts: {
      formValue: {
        name: '',
        lastName: '',
        mobile: '',
        primaryEmail: '',
        stateId: 2,
        cityId: 69,
        state: 'Antioquia',
        city: 'Medellín --',
        address: '',
      },
      formError: {},
      formModel: {},
    },
    customers: {
      editedCustomer: {},
      list: [],
      filters: {},
      paging: {
        pageSize: 10,
        currentPage: 1,
        totalRows: 10,
      },
      formValue: {
        idType: 'CC',
        identification: '',
        name: '',
        lastName: '',
        brandName: '',
        mobile: '',
        phone: '',
        primaryEmail: '',
        stateId: 2,
        cityId: 69,
        state: 'Antioquia',
        city: 'Medellín --',
        primaryAddress: '',
        paymentMethodId: 1,
        paymentTerms: 0,
        listPriceId: 1,
        fiscalResponsibility: '114',
        taxRegime: 'SIMPLIFIED_REGIME',
        costCenter: '',
        customFields: {},
        fulfillmentTypeId: '',
        defaultLocationId: '',
        channelId: '',
        preferredInvoicingSettings: 1,
        invoiceOnShipment: true,
        actionsOnShipment: 'INVOICE',
      },
      formError: {},
      formModel: {},
    },
    products: {
      editedProduct: {},
      list: [],
      filters: {},
      paging: {
        pageSize: 10,
        currentPage: 1,
        totalRows: 10,
      },
      formValue: {
        name: '',
        priceBeforeTax: '',
        tax: 1,
      },
      formError: {},
    },
    tags: {
      visible: false,
      selectedTags: { orderId: 0, tags: [] },
      list: [],
    },
  },
  multiOrderInvoicing: {
    selectedCustomerOrders: undefined,
    seletectedCustomer: 0,
    checkedKeys: [],
    invoiceDisabled: true,
    confirmVisible: false,
    invoiceNotes: '',
  },
  invoicedOrderData: {},
  customerIdType: 'NIT',
  gridsHandling: {
    expandedRowKeys: [0],
  },
  versionUpdates: {
    newVersionAvailable: false,
    waitingWorker: {},
  },
  deliverySlotsManagement: {
    fileUploader: {
      isOpen: false,
      dataLoaded: false,
      isFormInvalid: false,
      rows: null,
      cols: null,
      uploadedFileName: '',
    },
    processEnabled: false,
    gridRows: undefined,
    paging: {
      pageSize: 10,
      currentPage: 1,
      totalRows: 10,
    },
    editedSlot: {},
    slotModalVisible: false,
  },
  auth: {
    token: '',
  },
  authModal: {
    open: false,
  },
  modal: {
    title: '',
    message: '',
    open: false,
    textButtonOk: '',
    textButtonClose: '',
    handleButtonOk: () => {},
    handleButtonClose: () => {},
  },
  invoicingInProgress: false,
});

type State = {
  id: string;
  name: string;
  idSiigo: string;
  value: string;
  label: string;
};

type City = {
  id: string;
  name: string;
  idSiigo: string;
  stateId: string;
  value: string
  label: string
};

type Contact = {
  name: string;
  lastName: string;
  primaryEmail: string;
  mobile: string;
  city: City;
  state: State;
  address: string;
  stateId: string;
  cityId: string;
  defaultShippingAddress: Boolean;
  color: string;
};

export type NewOrderState = {
  action: 'EDIT' | 'COPY';
  id: string;
  customerId: string;
  totalBeforeTax: number;
  totalAfterTax: number;
  discount: number;
  statusId: string;
  comments: string;
  notes: string;
  incomingPurchaseOrder: string;
  paymentMethodId: string;
  promisedDeliveryDateTime: string;
  totalUnits: number;
  deliverySlotId: string;
  channelId: string;
  invoicingSettingsId: string;
  companyId: string;
  orderNumber: number;
  actionOnShipment: string;
  createdAt: string;
  updatedAt: string;
  fulfillmentLocationId: string;
  shippingAddress: Contact,
  customFields: CustomFieldsDataType
  fulfillmentType: {
    id: string;
    description: string;
    idDelivery: string;
    deliveryType: string;
    deliveryPartner: string;
    companyId: string;
  }
  tags: [{
    text?: string;
    color?: string;
  }]
  fulfillmentTypeId?: string,
  customer: {
    value: string;
    label: string;
    id: string;
    companyId: string;
    identification: string;
    actionOnShipment: string;
    address: string;
    channelId: string;
    createdAt: string;
    fiscalResponsibility: string;
    fullName: string;
    idShopify: string;
    idType: string;
    invoicingSettingId: string;
    lastName: string;
    brandName: string;
    listPriceId: string;
    mobile: string;
    name: string;
    paymentMethodId: string;
    paymentMethod: { description: string; idERP: string };
    paymentTerms: number;
    primaryEmail: string;
    source: string;
    taxRegime: string;
    costCenter: string;
    fulfillmentTypeId: string;
    defaultLocationId: string;
    updatedAt: string;
    idERP: string;
    state: State;
    city: City;
    contacts: [Contact];
  };
  status: {
    id: string;
    description: string;
    listingOrder: number;
    companyId: string;
    statusId: number;
    role: string;
    automatic: false;
    createdAt: string;
    updatedAt: string;
    deliverySlotId: string;
  };
  paymentMethod: {
    id: string;
    description: string;
    idERP: string;
    createdAt: string;
    updateAt: string;
    accountIdERP: string;
    receiptPaymentMethodIdERP: string;
    companyId: string;
  };
  deliverySlot: {
    id: string;
    companyId: string;
    startDateTime: string;
    endDateTime: string;
    capacity: number;
    createdAt: string;
    updatedAt: string;
  };
  invoicingSetting: {
    id: string;
    idERP: string;
    description: string;
    companyId: string;
    origin: string[];
    generateStamp: false;
  };
  channel: {
    id: string;
    description: string;
    priority: number;
    companyId: string;
  };
  listPrice: {
    id: string;
    description: string;
    companyId: string;
    origin: string[];
  };
  items: {
    sku: string;
    item: string;
    itemPrice: number;
    discount: number;
    itemTaxNumeric: number;
    itemTax: string;
    itemQuantity: number;
    itemTotal: number;
    delete: string;
    id: string;
    unitsCount: boolean;
    taxIdERP: string;
    idERP: string;
    productId: string;
    notes: string;
    active: boolean;
  }[];
  DeliverySlot: {};
  promisedDeliveryDate: string;
};
