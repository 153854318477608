import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as constants from '../Constants';
import {
  formatCurrencySpanishCO,
  formatDateAndHourSpanishCO
} from '../DataFormat';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export async function buildShippingList(orderTrackingList, companyId, permissions) {
  if (!Array.isArray(orderTrackingList)) {
    orderTrackingList = [orderTrackingList];
  }

  let dd = {
    pageSize: 'A5',
    pageOrientation: 'portrait',
    // pageOrientation: 'landscape',
    content: [],
  };
  let index;
  let isLastItem = false;

  // image should obtained just once
  const logo = constants.CUSTOMER_IMG(companyId);
  const img = await getBase64ImageFromURL(process.env.PUBLIC_URL + logo.src);

  const styles = getShippingListStyles();
  const qrData = companyId;

  for (index = 0; index < orderTrackingList.length; index++) {
    if (index == orderTrackingList.length - 1) isLastItem = true;
    const line1 = getShippingListLine1(
      img,
      logo,
      qrData + orderTrackingList[index].order.orderNumber,
    );
    const line2 = getShippingListLine2(orderTrackingList[index].order);
    const line3 = getShippingListLine3(
      orderTrackingList[index].orderItems,
      isLastItem,
      permissions
    );

    dd.content.push(line1);
    dd.content.push(line2);
    dd.content.push(line3);
  }

  // add a full object with the styles definition
  Object.defineProperty(dd, 'styles', {
    value: styles,
    writable: true,
    enumerable: true,
  });

  return dd;
}

const getShippingListLine1 = (img, logo, qrData) => {
  let line1 = {};
  let columns = [];

  columns.push({ image: img, width: logo.width, height: logo.height });
  columns.push({ qr: qrData, fit: 90, style: 'header' });
  Object.defineProperty(line1, 'columns', {
    value: columns,
    writable: true,
    enumerable: true,
  });

  return line1;
};

const getShippingListLine2 = order => {
  let line2 = {};
  let columns = [];
  let text = {};
  let innerText = [];
  let textCustomer = {};
  let innerTextCustomer = [];
  const contentFontSize = 9
  const titleFontSize = 11

  // setting up order general information
  const createdAt = formatDateAndHourSpanishCO(order.createdAt);
  const promisedDeliveryDateTime = formatDateAndHourSpanishCO(
    order.promisedDeliveryDateTime,
  );
  const orderId = order.orderNumber;
  const paymentMethod = order.paymentMethod.description;
  const shippingCyty = order.shippingAddress.city.name;
  let comments = order.comments;
  let commentsHeader = '';
  comments ? (commentsHeader = 'Observaciones: ') : (commentsHeader = '');
  if (!comments) comments = '';
  const customer = order.customer;
  const shippingAddress = order.shippingAddress;

  innerText.push({
    text: 'Savia #' + orderId + '\n',
    fontSize: titleFontSize,
    bold: true,
  });
  innerText.push({ text: '\n', fontSize: 11 });
  innerText.push({ text: 'Fecha: ', bold: true, fontSize: contentFontSize }, { text: createdAt, fontSize: contentFontSize } , '\n');
  innerText.push(
    { text: `Fecha entrega: `, bold: true, fontSize: contentFontSize }, { text: promisedDeliveryDateTime, fontSize: contentFontSize },  '\n',);
  innerText.push(
    { text: 'Método de pago: ', bold: true, fontSize: contentFontSize },
    { text: paymentMethod, fontSize: contentFontSize },
    '\n'
  );
  innerText.push(
    { text: 'Ciudad de despacho: ', bold: true, fontSize: contentFontSize },
    { text: shippingCyty, fontSize: contentFontSize },
    '\n'
  );
  innerText.push({ text: commentsHeader, bold: true, fontSize: contentFontSize }, { text: comments, fontSize: contentFontSize },  '\n' , '\n');
  Object.defineProperty(text, 'text', {
    value: innerText,
    writable: true,
    enumerable: true,
  });
  columns.push(text);

  // setting up customer detailed information
  innerTextCustomer.push({
    text: customer.fullName + '\n',
    fontSize: titleFontSize,
    bold: true,
    style: 'textRight',
  });
  innerTextCustomer.push({
    text: customer.idType + ' - ' + customer.identification + '\n',
    fontSize: contentFontSize,
    style: 'textRight',
  });
  innerTextCustomer.push({ text: shippingAddress.address + '\n', fontSize: contentFontSize, style: 'textRight' });
  innerTextCustomer.push({
    text: shippingAddress.primaryEmail + '\n',
    fontSize: contentFontSize,
    style: 'textRight',
  });
  innerTextCustomer.push({ text: shippingAddress.mobile + '\n', style: 'textRight', fontSize: contentFontSize });
  Object.defineProperty(textCustomer, 'text', {
    value: innerTextCustomer,
    writable: true,
    enumerable: true,
  });
  Object.defineProperty(textCustomer, 'style', {
    value: 'text-right',
    writable: true,
    enumerable: true,
  });
  columns.push(textCustomer);

  Object.defineProperty(line2, 'columns', {
    value: columns,
    writable: true,
    enumerable: true,
  });

  return line2;
};

const getShippingListLine3 = (orderItems, isLastItem, permissions) => {
  let line3 = {};

  Object.defineProperty(line3, 'style', {
    value: 'orderDetails',
    writable: true,
    enumerable: true,
  });

  const orderDetails = getOrderDetailValues(orderItems, permissions);
  let table = {
    headerRows: 1,
    widths: ['60%', '10%', '35%'],
    body: orderDetails,
  };
  Object.defineProperty(line3, 'table', {
    value: table,
    writable: true,
    enumerable: true,
  });

  Object.defineProperty(line3, 'layout', {
    value: 'lightHorizontalLines',
    writable: true,
    enumerable: true,
  });

  if (!isLastItem) {
    Object.defineProperty(line3, 'pageBreak', {
      value: 'after',
      writable: true,
      enumerable: true,
    });
  }

  return line3;
};

const getOrderDetailValues = (orderItems, permissions) => {
  let columns = [];
  let values = [];
  let totals = [];
  let amount = 0;
  let units = 0;
  const contentFontSize = 9
  const titleFontSize = 11

  // add header values
  columns.push({ text: 'Producto', style: 'tableHeader', fontSize: contentFontSize });
  columns.push({ text: 'Cant.', style: 'tableHeader', fontSize: contentFontSize });

  if (permissions.SHIPPING_LIST_DISPLAY_PRICES !== false) {
    columns.push({ text: 'Total', style: 'tableHeader', fontSize: contentFontSize });
  }
  
  values.push(columns);

  orderItems.forEach(item => {
    let valueRow = [];
    valueRow.push({
      text: [
        { text: item.product.name + '\n', style: 'tableContentLeft', fontSize: contentFontSize },
        { text: item.notes, fontSize: 8, color: '#1d4ed8' },
      ],
    });
    valueRow.push({ text: item.quantity, style: 'tableContentRight', fontSize: contentFontSize });
    
    if (permissions.SHIPPING_LIST_DISPLAY_PRICES !== false) {
      valueRow.push({
        text: formatCurrencySpanishCO(item.priceAfterTax),
        style: 'tableContentRight',
        fontSize: contentFontSize
      });
    }
    
    values.push(valueRow);

    // determine total for amount and number of units
    if (item.product.unitsCount) units += item.quantity;
    amount += Number(item.priceAfterTax) * item.quantity;
  });

  totals.push({ text: '', style: 'tableContentTotalRight' });
  totals.push({ text: units, style: 'tableContentTotalRight' });
  totals.push({
    text: formatCurrencySpanishCO(amount.toFixed(2)),
    style: 'tableContentTotalRight',
  });
  values.push(totals);

  return values;
};

export const downloadShippingList = (document, loggingParams) => {
  try {
    pdfMake.createPdf(document).open();
  } catch (error) {
    console.error(error);
  }
};

function getBase64ImageFromURL(url) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');

    img.onload = () => {
      var canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      var dataURL = canvas.toDataURL('image/png');
      resolve(dataURL);
    };

    img.onerror = error => {
      reject(error);
    };

    img.src = url;
  });
}

const getShippingListStyles = () => {
  const styles = {
    header: {
      fontSize: 12,
      alignment: 'right',
      margin: [0, 0, 0, 40],
    },
    orderDetails: {
      margin: [0, 5, 0, 15],
    },
    subheader: {
      fontSize: 12,
    },
    tableHeader: {
      bold: true,
      fontSize: 12,
      color: 'black',
      alignment: 'center',
    },
    textRight: {
      alignment: 'right',
    },
    tableContentRight: {
      alignment: 'right',
    },
    tableContentTotalRight: {
      alignment: 'right',
      bold: true,
      fontSize: 12,
    },
    tableContentCenter: {
      alignment: 'center',
    },
    tableContentLeft: {
      alignment: 'left',
    },
    superMargin: {
      margin: [20, 0, 40, 0],
      fontSize: 12,
    },
  };
  return styles;
};
