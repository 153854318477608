import { formatPercentage, formatToCalendarDate } from '../DataFormat';
import { cleanupString } from './Tools';

export const mapSearchFilters = payload => {
  let fields, values;
  let searchFilter = {};

  let columns = Object.keys(payload);
  let filters = Object.values(payload);

  fields = cleanupString(columns.join(','));
  values = cleanupString(filters.join(','));

  searchFilter.columns = fields;
  searchFilter.filters = values;

  return searchFilter;
};

export const mapDeliverySlot = ({ createdAt, updatedAt, ordersCapacity, companyId, ...payload }) => {
  const slot = { ...payload };
  slot.capacity = ordersCapacity;
  slot.startDateTime = formatToCalendarDate(slot.startDateTime);
  slot.endDateTime = formatToCalendarDate(slot.endDateTime);

  return slot;
};

export const mapTags = tags => {
  return tags.map(tag => ({
    label: tag.text,
    value: tag.text,
    color: tag.color,
  }));
};

export const mapListPrice = listPrice => {
  let mappedList = listPrice.map(list => {
    return { value: list.id, label: list.description };
  });
  return mappedList;
};

export const mapMasterDataList = (itemsList, list) => {
  let mappedList = itemsList.map(item => {
    if (list !== 'TAXES' && list !== 'FULFILLMENT_TYPES') {
      return {
        value: item.id,
        label: item.description || item.name,
        stateId: item.stateId || undefined,
        idSiigo: item.idSiigo || undefined,
      };
    } else if (list === 'FULFILLMENT_TYPES') {
      return {
        id: item?.id,
        description: item?.description,
        idDelivery: item?.idDelivery,
        deliveryType: item?.deliveryType,
        deliveryPartner: item?.deliveryPartner,
        companyId: item?.companyId,
        isDefault: item?.isDefault
      }
    } else {
      return {
        value: item.idERP,
        label: item.description + ' - ' + formatPercentage(item.percentage),
        percentage: item.percentage,
      };
    }
  });
  return mappedList;
};

export const mapTaxRegimeToString = tax => {
  let taxRegime;
  switch (true) {
    case tax === 1:
      taxRegime = 'SIMPLIFIED_REGIME';
      break;
    case tax === 2:
      taxRegime = 'COMMON_REGIME';
      break;
    case tax === 3:
      taxRegime = 'NOT_REPONSIBLE_FOR_CONSUMPTION';
      break;
    case tax === 4:
      taxRegime = 'SPECIAL_REGIME';
      break;
    case tax === 5:
      taxRegime = 'NATIONAL_CONSUMPTION_TAX';
      break;
    default:
      taxRegime = 'SIMPLIFIED_REGIME';
      break;
  }
  return taxRegime;
};

export const mapTaxRegimeToInt = tax => {
  let taxRegime;

  switch (tax) {
    case 'SIMPLIFIED_REGIME':
      taxRegime = 1;
      break;
    case 'COMMON_REGIME':
      taxRegime = 2;
      break;
    case 'NOT_REPONSIBLE_FOR_CONSUMPTION':
      taxRegime = 3;
      break;
    case 'SPECIAL_REGIME':
      taxRegime = 4;
      break;
    case 'NATIONAL_CONSUMPTION_TAX':
      taxRegime = 5;
      break;
    default:
      taxRegime = 1;
      break;
  }
  return taxRegime;
};
